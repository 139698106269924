.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Styles for FullCalendar plugin */

.fc {
  text-transform: uppercase;
}

.fc th {
  color: white;
  background-color: #86aa40;
  padding: 5px 0 !important;
  font-weight: normal;
  font-size: 12px;
}

.fc th.fc-sat,
.fc th.fc-sun {
  background-color: #0e988c;
}

.fc-day-number {
  /*color: #ffffffb3;*/
}

.fc-unthemed td {
  /*border-color: #063D1B !important;*/
}

.fc-unthemed td.fc-today {
  /*background: rgba(124, 172, 68, 0.3) !important;*/
  /*!*border: 3px solid #86AA40 !important;*!*/
  /*border-color: #86AA40 !important;*/
  /*border-width: 3px !important;*/
  /*border-style: solid !important;*/
}

.fc-unthemed td.fc-today .fc-day-number {
  /*color: white !important;*/
}

.fc-dayGrid-view .fc-body .fc-row {
  /*min-height: 50px;*/
}

.fc-toolbar h2 {
  /*color: rgba(255, 255, 255, 0.86);*/
  /*border-top: 2px solid green;*/
  /*border-bottom: 2px solid green;*/
  /*padding: 5px 0;*/
  font-weight: normal;
  /*font-size: 25px !important;*/
}

.fc-button-primary {
  background-color: transparent !important;
  border-color: transparent !important;
  font-size: 25px !important;
  padding: 0 !important;
  color: #053517 !important;
}

.fc-event {
  /*background-color: #7CAC44 !important;*/
  /*border: 3px solid #7CAC44 !important;*/
  /*text-align: center;*/
}

.fc-scroller {
  overflow-y: hidden !important;
  overflow-x: visible !Important;
}

.fc-event-container {
  padding: 0 !important;
  border-width: 1px !important;
  border-color: #7cac44 !important;
}

.fc table {
  border-collapse: separate !important;
}

.fc .fc-row .fc-content-skeleton td {
  border-bottom: none !important;
}

.fc .fc-row .fc-content-skeleton .fc-event-container {
  border-color: #7cac44 !important;
}

.fc-day-grid-event {
  /*padding: 0 !important;*/
  /*margin: 0 !important;*/
  /*border-radius: 0 !important;*/
}

button,
a {
  cursor: pointer;
  overflow-wrap: break-word;
}

/* photo gallery position */
.react-images__blanket {
  z-index: 1301 !important;
}

.react-images__positioner {
  z-index: 1301 !important;
}

/* google map search */
.pac-container {
  border: 0px !important;
}